import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import App from "./App";
import Portal from "./Portal";

const Routing = () => {
  const pathname = window.location.pathname;
  return (
    <Router>
      {pathname !== "/" && /\/$/.test(pathname) && (
        <Redirect to={pathname.slice(0, -1)} />
      )}
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/data" component={Portal} />
        {/* <Route path="*">{(window.location = "https://deftt.io/")}</Route> */}
      </Switch>
    </Router>
  );
};

export default Routing;
