import logo from "./DefttGradient.png";
import "./App.css";
import { Button, Form, Input, Label } from "semantic-ui-react";
import { useState } from "react";

function App() {
  const [accessCode, setAccessCode] = useState("");
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Management portal</p>
        <Form>
          <Form.Field>
            <label style={{ color: "white" }}>Enter access code</label>
            <input
              placeholder="Access code"
              onChange={(e) => setAccessCode(e.target.value)}
            />
          </Form.Field>
          <Button
            onClick={() => {
              window.location = "/data?access=" + accessCode;
            }}
          >
            Log in
          </Button>
        </Form>
      </header>
    </div>
  );
}

export default App;
