import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Table } from "semantic-ui-react";
import axios from "axios";

export default function Portal() {
  const [ticketData, setTicketData] = useState({});

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let accessCode = query.get("access");

  const getTicketData = async (accessCode) => {
    const config = {
      Authorization:
        "PMAK-6031af00aa70e20042cafe6c-6df2ca14105ff25019ffad3324bfbf79f9",
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_DIRECTORY}/admin-get-tickets?access=${accessCode}`,
      {
        headers: config,
      }
    );
    const data = await response.data;

    if (accessCode != "forum") {
      return (
        <p>Authentication error. Please press back button and try again.</p>
      );
    }
  };
  if (accessCode != "forum") {
    window.location = "/";
    return <p>Authentication error. Please press back button and try again.</p>;
  }
  //   getTicketData(accessCode);
  return (
    <div>
      {/* <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>John</Table.Cell>
            <Table.Cell>Approved</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table> */}
      <iframe
        src="https://charts.mongodb.com/charts-ticketing---dev-hkfdt/public/dashboards/60836976-c615-4530-831f-174d4f77a810"
        width="100%"
        style={{ height: "100vh" }}
      ></iframe>
    </div>
  );
}
